import React, { useEffect, useState } from "react";
import "./ProfilePage.css";  // Import the styles

export const ProfilePage = ({ onBackClick }) => {
    const [userData, setUserData] = useState(null);  // State to hold user data
    const [loading, setLoading] = useState(true);  // Loading state
    const [userId, setUserId] = useState(null);  // State to hold userId
    const API_TOKEN = process.env.REACT_APP_STATICA_API_TOKEN;

    useEffect(() => {
        // Check if the app is accessed via Telegram WebApp by checking for initDataUnsafe.user
        if (window.Telegram?.WebApp?.initDataUnsafe?.user) {
            console.log("Is accessed from TG");
            // Confirm it is a Telegram app and initialize the WebApp
            window.Telegram.WebApp.ready();

            // Get the user data from Telegram WebApp
            const user = window.Telegram.WebApp.initDataUnsafe.user;
            setUserId(user.id);  // Set the userId in state

            // Prepare user data to send to API
            const userData = {
                user_id: user.id,
                image_url: user.photo_url || '',  // Fallback to empty string if photo is unavailable
                username: user.username,
                first_name: user.first_name,
                last_name: user.last_name,
            };

            // Call the API to create/update user profile
            fetch("/api/update_user_profile", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${API_TOKEN}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                })
                .catch(error => {
                    console.error("Error updating user profile:", error);
                });
        } else {
            // For testing purposes, use a default user ID
            setUserId('12345678');
        }
    }, [API_TOKEN]);

    // Fetch user data from the API after userId is set
    useEffect(() => {
        if (userId) {  // Ensure userId is available before fetching data
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`/api/get_user_profile?user_id=${userId}`, {
                        headers: {
                            'Authorization': `Bearer ${API_TOKEN}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error fetching user data: ${response.status}`);
                    }

                    const data = await response.json();
                    setUserData(data);
                    setLoading(false);  // Stop loading once data is fetched
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    setLoading(false);
                }
            };

            fetchUserData();
        }
    }, [userId, API_TOKEN]);  // Re-run when userId is available

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userData) {
        return <div>Error: Could not fetch user data.</div>;
    }

    const handleSubscribe = () => {
        // Logic to handle premium subscription
        alert("Subscribed to Premium!");
    };

    // Check if the user image_url is empty, if so, use the placeholder image
    const profileImage = userData.image_url ? userData.image_url : "/img/statica_logo.png";

    return (
        <div className="profile-page">
            <div className="header">
                <div className="header-content">
                    <div className="header-title">Profile</div>
                </div>
            </div>

            <div className="profile-header">
                <img src={profileImage} alt="User profile" className="profile-image"/>
                <div className="user-info">
                    <h1>{userData.first_name} {userData.last_name}</h1>
                    <h2>@{userData.username}</h2>
                    <p>Subscription Status: <strong>{userData.subscription_status}</strong></p>
                    <button onClick={handleSubscribe} className="subscribe-button">
                        {userData.subscription_status === "free" ? "Subscribe to Premium" : "Premium Subscriber"}
                    </button>
                </div>
            </div>

            <div className="bookmakers-section">
                <h3>Select Bookmakers</h3>
                <div className="bookmakers-buttons">
                    {["1xbet", "bet365", "betway"].map((bookmaker) => (
                        <button
                            key={bookmaker}
                            className={`bookmaker-button ${userData.bookmakers.includes(bookmaker) ? "selected" : ""}`}
                            onClick={() => alert(`Selected ${bookmaker}`)}
                        >
                            {bookmaker}
                        </button>
                    ))}
                </div>
            </div>

            <div className="preferences-section">
                <h3>Followed Leagues</h3>
                <div className="preferences-buttons">
                    {userData.notifications.leagues.map((leagueId, index) => (
                        <button key={index} className="preference-button">
                            League {leagueId}
                        </button>
                    ))}
                </div>
                <h3>Followed Events</h3>
                <div className="preferences-buttons">
                    {userData.notifications.events.length > 0 ? (
                        userData.notifications.events.map((eventId, index) => (
                            <button key={index} className="preference-button">
                                Event {eventId}
                            </button>
                        ))
                    ) : (
                        <p>No followed events.</p>
                    )}
                </div>
            </div>
        </div>
    );
};
