import React, { useEffect, useState } from "react";
import "./FeedPage.css";
import { EventPage } from "../EventPage/EventPage";
import { ProfilePage } from "../ProfilePage/ProfilePage";

const sportIcons = {
    soccer: "/img/sports/soccer.svg",
    basketball: "/img/sports/basketball.svg",
    tennis: "/img/sports/tennis.svg",
    'ice hockey': "/img/sports/ice-hockey.svg",
    'american football': "/img/sports/american-football.svg",
};

export const FeedPage = () => {
    const [sports, setSports] = useState([]);
    const [selectedSport, setSelectedSport] = useState(null);
    const [leagues, setLeagues] = useState([]);

    const API_TOKEN = process.env.REACT_APP_STATICA_API_TOKEN;

    useEffect(() => {
        fetch("/api/get_sports", {
            headers: {
                'Authorization': `Bearer ${API_TOKEN}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setSports(data);
                if (data.length > 0) {
                    setSelectedSport(data[0]);
                }
            })
            .catch((error) => console.error("Error fetching sports:", error));
    }, [API_TOKEN]);

    useEffect(() => {
        if (selectedSport) {
            fetch(`/api/get_relevant_leagues/${selectedSport.sport_id}`, {
                headers: {
                    'Authorization': `Bearer ${API_TOKEN}`
                }
            })
                .then((response) => response.json())
                .then((data) => setLeagues(data))
                .catch((error) => console.error("Error fetching leagues:", error));
        }
    }, [selectedSport, API_TOKEN]);

    const handleSportClick = (sport) => {
        setSelectedSport(sport);
    };

    const handleLeagueClick = (league) => {
        const sportId = selectedSport ? selectedSport.sport_id : null;


    };

    return (
        <div className="feed-page">
            <div className="header">
                <div className="header-content">
                    <div className="header-title">Feed</div>
                </div>
            </div>

            <div className="sports-list">
                {sports.map((sport, index) => (
                    <div key={index} className="sport-tab" onClick={() => handleSportClick(sport)}>
                        <div className="sport-icon-frame">
                            <img
                                src={sportIcons[sport.sport_name] || "/img/sports/default-icon.svg"}
                                alt={sport.sport_name}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div className="leagues-section">
                <div className="section-header">
                    <div className="section-header-text">Select a League</div>
                </div>

                <div className='leagues-list'>
                    {selectedSport && leagues.length > 0 ? (
                        leagues.map((league, index) => (
                            <div key={index} className="league-row">
                                <div className="league-tab" onClick={() => handleLeagueClick(league)}>
                                    <div className="league-icon-frame">
                                        <div className="league-icon">🏆</div>
                                    </div>
                                    <div className="league-name">{league.league_name}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No leagues available for the selected sport</div>
                    )}
                </div>
            </div>

            <div className="insights-section">
                <div className="section-header">
                    <div className="section-header-text">Insights</div>
                </div>

                <div className="insight-item">
                    <div className="insight-icon-wrapper">
                        <div className="insight-icon">📊</div>
                    </div>

                    <div className="insight-title-wrapper">
                        <p className="p">Match insights for Team A vs Team B</p>
                    </div>

                    <div className="insight-subtitle">2.50</div>

                    <img className="insight-separator" alt="Separator" src="/img/vector-200.svg"/>
                </div>

                <div className="insight-item">
                    <div className="insight-icon-wrapper">
                        <div className="isnight-icon">📊</div>
                    </div>
                    <div className="insight-title-wrapper">
                        <p className="p">Player statistics for Player X</p>
                    </div>
                    <div className="insight-subtitle">3.00</div>

                    <img className="insight-separator" alt="Separator" src="/img/vector-200.svg"/>
                </div>
            </div>
        </div>
    );
};
