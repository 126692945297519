import React, { useState, useEffect } from "react";
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ReferenceLine
} from 'recharts';
import "./EventPage.css";

// EventPage Component
export const EventPage = ({ onBackClick, eventId }) => {
  const [eventData, setEventData] = useState(null);  // State to hold event data
  const [loading, setLoading] = useState(true);  // Loading state
  const [bettingMarkets, setBettingMarkets] = useState([]);  // State for betting markets
  const [selectedTeamId, setSelectedTeamId] = useState(null);  // State to hold selected team_id
  const [selectedGames, setSelectedGames] = useState(10);  // Default number of games
  const [selectedMarket, setselectedMarket] = useState(null);  // State for selected event type
  const [summarizedEvents, setSummarizedEvents] = useState([]);  // State to hold summarized events
  const [processedData, setProcessedData] = useState([]);  // Data to display on the graph
  const [selectedDuration, setSelectedDuration] = useState('Full Game');  // State for selected duration
  const [availableLines, setAvailableLines] = useState([]);  // State to hold available lines
  const [selectedLine, setSelectedLine] = useState(null);  // State for the selected line
  const [lineValue, setLineValue] = useState(null); // State for global access to lineValue
  const [bookmakerOdds, setBookmakerOdds] = useState({});  // State to hold bookmaker odds

  // Access the API token from the environment variable
  const API_TOKEN = process.env.REACT_APP_STATICA_API_TOKEN;

  // Fetch event data from Flask API when component mounts
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`/api/get_event_info/${eventId}`, {
          headers: {
            'Authorization': `Bearer ${API_TOKEN}`
          }
        });

        if (!response.ok) {
          throw new Error(`Error fetching event data: ${response.status}`);
        }

        const data = await response.json();
        setEventData(data);  // Store fetched event data
      } catch (error) {
        console.error("Error fetching event data:", error);
      } finally {
        setLoading(false);  // Stop loading
      }
    };

    fetchEventData();
  }, [eventId, API_TOKEN]);

  // Fetch betting markets after event data is loaded
  useEffect(() => {
    const fetchBettingMarkets = async () => {
      if (eventData && eventData.sport_id) {
        try {
          const response = await fetch(`/api/get_betting_markets/${eventData.sport_id}`, {
            headers: {
              'Authorization': `Bearer ${API_TOKEN}`
            }
          });
          if (!response.ok) {
            throw new Error(`Error fetching betting markets: ${response.status}`);
          }
          const data = await response.json();
          setBettingMarkets(data.betting_markets);
        } catch (error) {
          console.error("Error fetching betting markets:", error);
        }
      }
    };

    fetchBettingMarkets();
  }, [eventData, API_TOKEN]);

  // Set default selections after eventData and bettingMarkets are loaded
  useEffect(() => {
    if (eventData && bettingMarkets.length > 0) {
      setSelectedTeamId(eventData.home_id);
      setselectedMarket(bettingMarkets[0]);
    }
  }, [eventData, bettingMarkets]);

  // Fetch summarized events when selectedTeamId changes
  useEffect(() => {
    const fetchSummarizedEvents = async () => {
      if (selectedTeamId) {
        try {
          const response = await fetch(`/api/get_team_stats/${selectedTeamId}`, {
            headers: {
              'Authorization': `Bearer ${API_TOKEN}`
            }
          });
          if (!response.ok) {
            throw new Error(`Error fetching summarized events: ${response.status}`);
          }
          const data = await response.json();
          setSummarizedEvents(data);  // Store the summarized events
        } catch (error) {
          console.error("Error fetching summarized events:", error);
        }
      }
    };

    fetchSummarizedEvents();
  }, [selectedTeamId, API_TOKEN]);


  // Transform odds data for new structure
  const transformOddsData = (oddsObject, allLines) => {
    return Object.entries(oddsObject).reduce((acc, [bookmaker, lines]) => {
      if (bookmaker !== 'best') {  // Skip 'Best' bookmaker
        acc[bookmaker] = {};

        // Ensure all available lines are covered, even if some are missing
        allLines.forEach(line => {
          acc[bookmaker][line] = lines[line] ? lines[line] : { cf: 'N/A' }; // Set to 'N/A' or another default if missing
        });
      }
      return acc;
    }, {});
  };


  // Fetch lines and odds based on selected options
  useEffect(() => {
    const fetchLinesAndOdds = async () => {
      if (selectedMarket) {
        try {
          const teamType = selectedTeamId === eventData.home_id ? "Home" : "Away";
          const response = await fetch(`/api/get_lines_and_odds/${eventId}?market=${selectedMarket}&duration=${selectedDuration}&team_type=${teamType}`, {
            headers: {
              'Authorization': `Bearer ${API_TOKEN}`
            }
          });

          if (!response.ok) throw new Error(`Error fetching lines and odds: ${response.status}`);

          const data = await response.json();
          setAvailableLines([...data.lines]); // Convert to array
          setBookmakerOdds(transformOddsData(data.odds, data.lines));

          if (data.lines.length > 0) {
            setSelectedLine(data.lines[0]);
            const [, value] = data.lines[0].split(' ');
            setLineValue(value ? parseFloat(value) : null);
          } else {
            setSelectedLine(null);
            setLineValue(null);
          }
        } catch (error) {
          console.error("Error fetching lines and odds:", error);
        }
      }
    };

    fetchLinesAndOdds();
  }, [selectedMarket, selectedDuration, selectedTeamId, eventId, API_TOKEN]);


  // Handle line selection
  const handleLineSelection = (line) => {
    setSelectedLine(line);
    const [_, value] = selectedMarket === 'Handicap' ? [selectedLine[0] === '-' ? '-' : '+', selectedLine] : selectedLine.split(' ');
    const parsedLineValue = value ? parseFloat(value) : null;

    if (selectedMarket !== 'Handicap') {
      setLineValue(parsedLineValue);
    } else {
      setLineValue(-parsedLineValue); // Logic with Handicap is flipped
    }
  };


  // Map available lines for display without needing to parse team-specific info
  const getFilteredLines = () => {
    return availableLines.map(line => {
      const [type, value] = line.split(' ');
      return value ? `${type} ${value}` : type; // Display type and value if both exist, else just type
    });
  };


  // Process data for graph
  useEffect(() => {
    if (summarizedEvents.length > 0 && selectedMarket && selectedGames && selectedDuration && selectedLine) {
      const [type, value] = selectedMarket === 'Handicap' ? [selectedLine[0] === '-' ? '-' : '+', selectedLine] : selectedLine.split(' ');
      const parsedLineValue = value ? parseFloat(value) : null;

      if (selectedMarket !== 'Handicap') {
        setLineValue(parsedLineValue);
      } else {
        setLineValue(-parsedLineValue); // Logic with Handicap is flipped
      }


      const gamesToAnalyze = summarizedEvents.slice(0, selectedGames);

      const dataForGraph = gamesToAnalyze.map(game => {
        let baseData = {
          event_time: game.event_time,
          home_team: game.home_name,
          away_team: game.away_name,
        };

        let isSuccess;

        switch (selectedMarket) {
          case 'Total Goals': {
            // Calculate total goals for the selected duration
            const totalGoals = selectedDuration === 'Full Game'
                ? game['total_fulltime_n_scored']
                : selectedDuration === '1H'
                    ? game['total_1h_n_scored']
                    : game['total_2h_n_scored'];
            isSuccess = type === 'OVER' ? totalGoals >= parsedLineValue : totalGoals <= parsedLineValue;
            return {
              ...baseData,
              total_n_scored: totalGoals,
              fill: isSuccess ? "green" : "red"
            };
          }

          case 'Individual Total': {
            // Calculate the goals scored by the selected team for the duration
            const totalGoals = selectedDuration === 'Full Game'
                ? game['fulltime_n_scored']
                : selectedDuration === '1H'
                    ? game['1h_n_scored']
                    : game['2h_n_scored'];
            isSuccess = type === 'OVER' ? totalGoals >= parsedLineValue : totalGoals <= parsedLineValue;
            return {
              ...baseData,
              team_n_scored: totalGoals,
              fill: isSuccess ? "green" : "red"
            };
          }

          case 'Handicap': {
            // Determine goal difference based on selected duration
            const goalDifference = selectedDuration === 'Full Game'
                ? game['fulltime_n_scored'] - game['fulltime_n_missed']
                : selectedDuration === '1H'
                    ? game['1h_n_scored'] - game['1h_n_missed']
                    : game['2h_n_scored'] - game['2h_n_missed'];

            const handicapSuccess = goalDifference + parsedLineValue >= 0;

            return {
              ...baseData,
              goal_difference: goalDifference,
              fill: handicapSuccess ? "green" : "red"
            };
          }

          case 'Both Score': {
            // Check if both teams scored in the selected duration
            const bothScore = selectedDuration === 'Full Game'
                ? game['fulltime_both_teams_scored']
                : selectedDuration === '1H'
                    ? game['1h_both_teams_scored']
                    : game['2h_both_teams_scored'];
            isSuccess = (type === 'YES' && bothScore) || (type === 'NO' && !bothScore);
            return {
              ...baseData,
              both_teams_scored: (type === 'YES' && bothScore) || (type === 'NO' && !bothScore) ? 1 : 0,
              fill: isSuccess ? "green" : "red"
            };
          }

          case 'Result': {
              const result = selectedDuration === 'Full Game'
                  ? game['fulltime_won']
                  : selectedDuration === '1H'
                      ? game['1h_won']
                      : game['2h_won'];
              isSuccess = (selectedLine === 'P1' && result === 1) ||
                  (selectedLine === 'PX' && result === 0) ||
                  (selectedLine === 'P2' && result === -1) ||
                  (selectedLine === '1X' && (result === 1 || result === 0)) ||
                  (selectedLine === 'X2' && (result === 0 || result === -1));
              return {
                ...baseData,
                won: result,
                fill: isSuccess ? "green" : "red"
              };
            }

          case 'Total Corners': {
            // Calculate total corners for the selected duration
            const totalCorners = selectedDuration === 'Full Game'
                ? game['n_corners_total']
                : selectedDuration === '1H'
                    ? game['1h_corners_total']
                    : game['2h_corners_total'];
            isSuccess = type === 'OVER' ? totalCorners >= parsedLineValue : totalCorners <= parsedLineValue;
            return {
              ...baseData,
              corners_total: totalCorners,
              fill: isSuccess ? "green" : "red"
            };
          }

          default:
            return baseData;
        }
      });

      setProcessedData(dataForGraph);
    }
  }, [summarizedEvents, selectedMarket, selectedGames, selectedDuration, selectedLine]);


  // Display loading message if data is not yet fetched
  if (loading) return <div>Loading...</div>;

  // Display error message if no event data is available
  if (!eventData || !eventData.home_name || !eventData.away_name) return <div>No event data available</div>;

  // Generate team image URLs or fallback to sport logo
  const homeTeamImage = eventData.home_image_id
      ? `https://assets.b365api.com/images/team/b/${eventData.home_image_id}.png`
      : `/img/sport_logos/${eventData.sport_name}_logo.png`;

  const awayTeamImage = eventData.away_image_id
      ? `https://assets.b365api.com/images/team/b/${eventData.away_image_id}.png`
      : `/img/sport_logos/${eventData.sport_name}_logo.png`;

  return (
      <div className="event-page">
        <div className="header">
          <div className="header-content">
            <img
                className="back-icon"
                alt="Back"
                src="/img/back.svg"
                onClick={onBackClick}
            />
          </div>
        </div>

        <div className="teams-section">
          <div className="team-name-wrapper">
            <div className="team-name">{eventData.home_name}</div>
          </div>
          <img className="team-avatar" alt="Home Team" src={homeTeamImage}/>
          <div className="vs-wrapper">
            <div className="vs-text">vs</div>
          </div>
          <img className="team-avatar" alt="Away Team" src={awayTeamImage}/>
          <div className="team-name-wrapper">
            <div className="team-name">{eventData.away_name}</div>
          </div>
        </div>

        {/* Team Selection Section */}
        <div className="selection-section">
          <div className="selection-title">Select Team</div>
          <div className="team-options">
            <div
                className={`team-option ${selectedTeamId === eventData.home_id ? "selected" : ""}`}
                onClick={() => setSelectedTeamId(eventData.home_id)}
            >
              <div className="option-text">{eventData.home_name}</div>
            </div>
            <div
                className={`team-option ${selectedTeamId === eventData.away_id ? "selected" : ""}`}
                onClick={() => setSelectedTeamId(eventData.away_id)}
            >
              <div className="option-text">{eventData.away_name}</div>
            </div>
          </div>
        </div>

        {/* Game Selection Section */}
        <div className="selection-section">
          <div className="selection-title">Select Number of Games</div>
          <div className="game-options">
            {[5, 10, 20].map((games) => (
                <div
                    key={games}
                    className={`game-option ${selectedGames === games ? "selected" : ""}`}
                    onClick={() => setSelectedGames(games)}
                >
                  <div className="option-text">{games}</div>
                </div>
            ))}
          </div>
        </div>

        {/* Betting Market Selection Section */}
        <div className="selection-section">
          <div className="selection-title">Select Betting Market</div>
          <div className="market-options">
            {bettingMarkets.map((market) => (
                <div
                    key={market}
                    className={`market-option ${selectedMarket === market ? "selected" : ""}`}
                    onClick={() => setselectedMarket(market)}
                >
                  <div className="option-text">{market}</div>
                </div>
            ))}
          </div>
        </div>

        {/* Duration Selection Section */}
        <div className="selection-section">
          <div className="selection-title">Select Duration</div>
          <div className="duration-options">
            {['Full Game', '1H', '2H'].map((duration) => (
                <div
                    key={duration}
                    className={`duration-option ${selectedDuration === duration ? "selected" : ""}`}
                    onClick={() => setSelectedDuration(duration)}
                >
                  <div className="option-text">{duration}</div>
                </div>
            ))}
          </div>
        </div>

        {/* Updated Line Selection Dropdown */}
        <div className="selection-section">
          <div className="selection-title">Available Lines</div>
          <select value={selectedLine} onChange={e => handleLineSelection(e.target.value)}>
            <option value="" disabled>Select a line</option>
            {getFilteredLines().map((line, index) => (
                <option key={index} value={line}>{line}</option>
            ))}
          </select>
        </div>

        {/* Container for bar graph */}
        <div className="graph-section">
          <h3>{selectedMarket} in {selectedDuration}</h3>
          {processedData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={processedData.reverse()}
                          margin={{ top: 0, right: 0, left: -25, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="event_time"/>
                  <YAxis tick={{ dx: -5 }}/>
                  <Tooltip/>
                  {/* Adjust the dataKey based on the selected betting market */}
                  {selectedMarket === 'Result' && (
                      <Bar dataKey="won" fill={({ payload }) => payload.fill} />
                  )}
                  {selectedMarket === 'Total Goals' && (
                      <Bar dataKey="total_n_scored" fill={({ payload }) => payload.fill} />
                  )}
                  {selectedMarket === 'Individual Total' && (
                      <Bar dataKey="team_n_scored" fill={({ payload }) => payload.fill} />
                  )}
                  {selectedMarket === 'Handicap' && (
                      <Bar dataKey="goal_difference" fill={({ payload }) => payload.fill} />
                  )}
                  {selectedMarket === 'Both Score' && (
                      <Bar dataKey="both_teams_scored" fill={({ payload }) => payload.fill} />
                  )}
                  {selectedMarket === 'Total Corners' && (
                      <Bar dataKey="corners_total" fill={({ payload }) => payload.fill} />
                  )}
                  {/* Add the ReferenceLine if a line is selected */}
                  {lineValue && (
                      <ReferenceLine y={lineValue} stroke="red" />
                  )}
                </BarChart>
              </ResponsiveContainer>
          ) : (
              <p>No data available for the selected options.</p>
          )}
        </div>

        {/* Display all bookmakers and odds for selected line */}
        <div className="selection-section">
          <div className="selection-title">Bookmaker Odds for Selected Line: Ad 18+</div>
          <div className="bookmaker-options">
            {Object.entries(bookmakerOdds).map(([bookmaker, odds]) => (
                <div
                    key={bookmaker}
                    className="bookmaker-option"  // Add specific class for bookmaker styling
                    onClick={() => {/* Optional click functionality, e.g., highlight selected bookmaker */}}
                >
                  {/* Display bookmaker logo */}
                  <img
                      src={`/img/bookmakers/${bookmaker}-logo.png`}  // Example path to bookmaker logos
                      alt={`${bookmaker} logo`}
                      className="bookmaker-logo"
                  />

                  {/* Display odds */}
                  <div className="option-text">
                    {odds[selectedLine]["cf"] || "N/A"}
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};
